<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="route.account" class="form-group col-sm-12 col-md-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="route.account.account_name">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
          <label for="route_name">Route Name:</label>
          <input class="form-control" required="" name="route_name" type="text" v-model="route.route_name">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_code">Route Code:</label>
        <input class="form-control" required="" name="route_code" type="text" v-model="route.route_code">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_start">Location Start:</label>
        <Select2 name="route_start_id" v-model.number="route.route_start_id" :options="stopOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="route_end">Location End:</label>
        <Select2 name="route_end_id" v-model.number="route.route_end_id" :options="stopOptions" />
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_distance">Distance (km):</label>
        <input class="form-control" required="" name="route_distance" type="number" step="any" min="0" v-model.number="route.route_distance">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="route_direction">Direction:</label>
        <select class="form-control" name="route_direction" disabled v-model.number="route.route_direction">
          <option value="1" style="color:black;">One Way</option>
          <option value="2" style="color:black;">Two Ways</option>
          <option value="3" style="color:black;">Sub Routes</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="route_direction">Route Color:</label>
        <input class="form-control" required="" type="color" name="route_color" v-model="route.route_color">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="route.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('routes', ['route', 'onRequest']),
        ...mapState('stops', ['stops']),
    },
    data(){
      return {
        stopOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.edit(this.route)
      },
      ...mapActions('routes', ['edit', 'get']),
      ...mapActions('stops', {getStops: 'get_stop_by_account'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Route')
      this.get(this.$route.params.id)
    },
    watch: {
      route (nextState, prevState) {
        if(nextState !== prevState){
          this.getStops({account_id: nextState.account_id})
          return
        }
      },
      stops(nextState, prevState){
        if(nextState !== prevState){
          this.stopOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.stopOptions.push({id:item.id, text:item.stop_name})
            })
          }
          return
        }
      }
    },
  }
</script>
