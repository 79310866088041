<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" v-model.number="sim_card.account_id" :options="accountOptions"/>
      </div>
      <div class="form-group col-sm-12 required">
          <label for="name">GSM No:</label>
          <input class="form-control" required="" name="gsm_no" type="text" v-model="sim_card.gsm_no">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="sim_card_code">Operator:</label>
        <select class="form-control" name="status" v-model="sim_card.operator" required>
          <option value="Telkomsel" style="color:black;">Telkomsel</option>
          <option value="XL Axiata" style="color:black;">XL Axiata</option>
          <option value="3 Tri" style="color:black;">3 Tri</option>
          <option value="Indosat Ooredoo" style="color:black;">Indosat Ooredoo</option>
          <option value="Smartfren" style="color:black;">Smartfren</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <label for="name">MSIDN:</label>
        <input class="form-control" name="msidn" type="text" v-model="sim_card.msidn">
      </div>
      <div class="form-group col-sm-12">
        <label for="name">IMSI No:</label>
        <input class="form-control" name="imsi" type="text" v-model="sim_card.imsi">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="sim_card.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/sim_cards">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('accounts', ['accounts']),
        ...mapState('sim_cards', ['onRequest']),
    },
    data(){
      return {
        sim_card: {
          gsm_no: '',
          operator: '',
          msidn: '',
          imsi: '',
          status: 1,
        },
        accountOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {sim_card} = this
        this.add(sim_card)
      },
      ...mapActions('sim_cards', ['add']),
      ...mapActions('accounts', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Add New Sim Card')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
