<template>
    <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6">
          <label for="parent_id">Account:</label>
          <input class="form-control" v-if="route.account" disabled name="route_name" type="text" v-model="route.account.account_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
          <label for="route_name">Route Name:</label>
          <input class="form-control" disabled name="route_name" type="text" v-model="route.route_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_code">Route Code:</label>
        <input class="form-control" disabled name="route_code" type="text" v-model="route.route_code">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_distance">Distance (km):</label>
        <input class="form-control" disabled name="route_distance" type="number" step="any" min="0" v-model.number="route.route_distance">
      </div>
      <div v-if="route.route_start" class="form-group col-sm-12 col-lg-6">
        <label for="route_start">Start:</label>
        <input class="form-control" disabled name="route_start" type="text" v-model="route.route_start.stop_name">
      </div>
      <div v-if="route.route_end" class="form-group col-sm-12 col-lg-6">
        <label for="route_end">End:</label>
        <input class="form-control" disabled name="route_end" type="text" v-model="route.route_end.stop_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_direction">Direction:</label>
        <input class="form-control" disabled v-if="route.route_direction == 1" name="route_end" type="text" value="One Way">
        <input class="form-control" disabled v-if="route.route_direction == 2" name="route_end" type="text" value="Two Ways">
        <input class="form-control" disabled v-if="route.route_direction == 3" name="route_end" type="text" value="Sub Routes">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_direction">Route Color:</label>
        <input class="form-control" disabled type="color" name="route_color" v-model="route.route_color">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="text-center text-bold">INBOUND</h5>
            <table class="table">
              <tr>
                <th>Stop</th>
                <th>ETA</th>
                <th>Stop Duration</th>
                <th>Distance (meters)</th>
                <th>Action</th>
              </tr>
              <tr v-for="(stop, index) in inbounds" :key="index">
                <td v-if="index > 0 && index < inbounds.length - 1">
                  <Select2 name="stop_id" v-model.number="inbounds[index].stop_id" :options="stopOptions" required/>
                </td>
                <td v-else>
                  <select class="form-control" name="stop_id" v-model.number="inbounds[index].stop_id" disabled>
                    <option v-for="st in stops" :key="st" :value="st.id">{{st.stop_name}}</option>
                  </select>
                </td>
                <td><input class="form-control" type="text" required v-model.number="inbounds[index].eta"></td>
                <td><input class="form-control" type="text" required v-model.number="inbounds[index].stop_duration"></td>
                <td><input class="form-control" type="text" required v-model.number="inbounds[index].distance"></td>
                <td>
                  <span v-if="index > 1 && index < inbounds.length - 1" class="btn btn-success mr-1" @click.prevent="moveUp(index, 'inbound')"><i class="fa fa-caret-square-up"></i></span>
                  <span v-if="index < inbounds.length - 2 && index > 0" class="btn btn-success mr-1" @click.prevent="moveDown(index, 'inbound')"><i class="fa fa-caret-square-down"></i></span>
                  <span v-if="index < inbounds.length - 1" class="btn btn-primary mr-1" @click.prevent="addIndex(index, 'inbound')"><i class="fa fa-plus"></i></span>
                  <span v-if="index > 0 && index < inbounds.length - 1" class="btn btn-danger mr-1" @click.prevent="deleteIndex(index, 'inbound')"><i class="fa fa-trash"></i></span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div v-if="route.route_direction == 2" class="col-sm-12 col-md-6">
        <div class="card">
          <div class="card-body">
            <h5 class="text-center text-bold">OUTBOUND</h5>
            <table class="table">
              <tr>
                <th>Stop</th>
                <th>ETA</th>
                <th>Stop Duration</th>
                <th>Distance (meters)</th>
                <th>Action</th>
              </tr>
              <tr v-for="(stop, index) in outbounds" :key="index">
                <td v-if="index > 0 && index < outbounds.length - 1">
                  <Select2 name="stop_id" v-model.number="outbounds[index].stop_id" :options="stopOptions" required/>
                </td>
                <td v-else>
                  <select class="form-control" name="stop_id" v-model.number="outbounds[index].stop_id" disabled>
                    <option v-for="st in stops" :key="st" :value="st.id">{{st.stop_name}}</option>
                  </select>
                </td>
                <td><input class="form-control" required type="text" v-model.number="outbounds[index].eta"></td>
                <td><input class="form-control" required type="text" v-model.number="outbounds[index].stop_duration"></td>
                <td><input class="form-control" required type="text" v-model.number="outbounds[index].distance"></td>
                <td>
                  <span v-if="index > 1 && index < outbounds.length - 1" class="btn btn-success mr-1" @click.prevent="moveUp(index, 'outbound')"><i class="fa fa-caret-square-up"></i></span>
                  <span v-if="index < outbounds.length - 2 && index > 0" class="btn btn-success mr-1" @click.prevent="moveDown(index, 'outbound')"><i class="fa fa-caret-square-down"></i></span>
                  <span v-if="index < outbounds.length - 1" class="btn btn-primary mr-1" @click.prevent="addIndex(index, 'outbound')"><i class="fa fa-plus"></i></span>
                  <span v-if="index > 0 && index < outbounds.length - 1" class="btn btn-danger mr-1" @click.prevent="deleteIndex(index, 'outbound')"><i class="fa fa-trash"></i></span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/routes">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
        ...mapState('routes', ['onRequest', 'route']),
        ...mapState('stops', ['stops']),
    },
    data() {
      return {
        inbounds: [],
        outbounds: [],
        stopOptions: [],
      }
    },
    components:{
      Select2
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        let route_stops = []
        let arr = this.inbounds.filter(item => item.stop_id > 0)
        arr.map((item, index) => {
          item.sequence = index + 1
          const {bound, distance, eta, sequence, stop_id, stop_duration} = item
          route_stops.push({bound, distance, eta, sequence, stop_id, stop_duration})
        })
        if(this.route.route_direction === 2) {
          arr = this.outbounds.filter(item => item.stop_id > 0)
          arr.map((item, index) => {
            item.sequence = index + 1
            const {bound, distance, eta, sequence, stop_id, stop_duration} = item
            route_stops.push({bound, distance, eta, sequence, stop_id, stop_duration})
          })
        }
        let data = {
          id: this.route.id,
          route_stops: route_stops
        }
        this.edit_route_stops(data)
      },
      moveUp(index, bound){
        if(bound === 'inbound'){
          var a = this.inbounds[index]
          this.inbounds[index] = this.inbounds[index - 1]
          this.inbounds[index - 1] = a
        } else {
          let a = this.outbounds[index]
          this.outbounds[index] = this.outbounds[index - 1]
          this.outbounds[index - 1] = a
        }
      },
      moveDown(index, bound){
        if(bound === 'inbound'){
          let a = this.inbounds[index]
          this.inbounds[index] = this.inbounds[index + 1]
          this.inbounds[index + 1] = a
        } else {
          let a = this.outbounds[index]
          this.outbounds[index] = this.outbounds[index + 1]
          this.outbounds[index + 1] = a
        }
      },
      addIndex(index, bound){
        if(bound === 'inbound'){
          let obj = {
            stop_id: '',
            route_id: this.route.id,
            sequence: 0,
            eta: 0,
            stop_duration: 0,
            distance: 0,
            bound: 'inbound',
            stop: null
          }
          this.inbounds.splice(index + 1, 0, obj)
        } else {
          let obj = {
            stop_id: '',
            route_id: this.route.id,
            sequence: 0,
            eta: 0,
            stop_duration: 0,
            distance: 0,
            bound: 'outbound',
            stop: null
          }
          this.outbounds.splice(index + 1, 0, obj)
        }
      },
      deleteIndex(index, bound){
        if(bound === 'inbound'){
          this.inbounds.splice(index, 1)
          if(this.inbounds.length < 1){
            let obj = {
              stop_id: '',
              route_id: this.route.id,
              sequence: 0,
              eta: 0,
              stop_duration: 0,
              distance: 0,
              bound: 'inbound',
              stop: null
            }
            this.inbounds.splice(0, 0, obj)
          }
        } else {
          this.outbounds.splice(index, 1)
          if(this.outbounds.length < 1){
            let obj = {
              stop_id: '',
              route_id: this.route.id,
              sequence: 0,
              eta: 0,
              stop_duration: 0,
              distance: 0,
              bound: 'outbound',
              stop: null
            }
            this.outbounds.splice(0, 0, obj)
          }
        }
      },
      ...mapActions('routes', ['edit_route_stops', 'get']),
      ...mapActions('stops', {getStops: 'get_stop_by_account'}),
    },
    created() {
      this.$emit('onChildInit', 'Edit Route Stops')
      this.get(this.$route.params.id)
    },
    watch: {
      route (nextState, prevState) {
        if(nextState !== prevState){
          this.getStops({account_id: nextState.account_id})
          if(nextState.route_stops){
            this.inbounds = nextState.route_stops.filter(item => item.bound === 'inbound')
            this.outbounds = nextState.route_stops.filter(item => item.bound === 'outbound')
            this.inbounds.sort((a,b) => (a.sequence > b.sequence) ? 1 : -1)
            this.outbounds.sort((a,b) => (a.sequence > b.sequence) ? 1 : -1)
            if(this.inbounds.length < 1) {
              this.addIndex(0, 'inbound')
            }
            if(this.outbounds.length < 1 && nextState.route_direction === 2) {
              this.addIndex(0, 'outbound')
            }
          }
          return
        }
      },
      stops(nextState, prevState){
        if(nextState !== prevState){
          this.stopOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.stopOptions.push({id:item.id, text:item.stop_name})
            })
          }
          return
        }
      }
    },
  }
</script>
<style>
.select2 {
  width: 100% !important;
}
</style>
