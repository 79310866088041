<template>
  <Datatable v-bind:entries="sensors" :columns="columns" :title="'Alert'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { getCapability } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('sensors', ['sensors']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'sensor_name', text: 'Name'},
            {name: 'sensor_type', text: 'Type'},
            {name: 'tag_name', text: 'Parameter / Tag Name'},
            {name: 'show_graph', text: 'Show Graph',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Yes'},
                  {value: '2', text: 'No'},
                ]
              }
            },
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_sensor', this.user) ? {event: 'edit_sensor', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_sensor', this.user) ? {event: 'sensors/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
        }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('sensors', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Sensor', getCapability('add_sensor', this.user) ? '/sensors/add' : '')
      this.get_all()
    },
  }
</script>
