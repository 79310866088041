<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="row">
        <div class="form-group col-sm-12 required">
          <label for="name">Sensor Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="sensors.sensor_name">
        </div>
        <div class="form-group col-sm-12 required">
            <label for="type">Type:</label>
            <select class="form-control" required name="type" v-model.number="sensors.sensor_type">
              <option value="" style="color:black;">-- Please Select One --</option>
              <option value="acc" style="color:black;">acc</option>
              <option value="battery" style="color:black;">battery</option>
              <option value="door" style="color:black;">door</option>
              <option value="engine" style="color:black;">engine</option>
              <option value="engine_hours" style="color:black;">engine_hours</option>
              <option value="fuel_tank" style="color:black;">fuel_tank</option>
              <option value="fuel_tank_calibration" style="color:black;">fuel_tank_calibration</option>
              <option value="gsm" style="color:black;">gsm</option>
              <option value="harsh_acceleration" style="color:black;">harsh_acceleration</option>
              <option value="harsh_breaking" style="color:black;">harsh_breaking</option>
              <option value="ignition" style="color:black;">ignition</option>
              <option value="load" style="color:black;">load</option>
              <option value="logical" style="color:black;">logical</option>
              <option value="numerical" style="color:black;">numerical</option>
              <option value="odometer" style="color:black;">odometer</option>
              <option value="rfid" style="color:black;">rfid</option>
              <option value="route_color" style="color:black;">route_color</option>
              <option value="satellites" style="color:black;">satellites</option>
              <option value="seatbelt" style="color:black;">seatbelt</option>
              <option value="speed_ecm" style="color:black;">speed_ecm</option>
              <option value="tachometer" style="color:black;">tachometer</option>
              <option value="temperature" style="color:black;">temperature</option>
              <option value="temperature_calibration" style="color:black;">temperature_calibration</option>
              <option value="textual" style="color:black;">textual</option>
            </select>
        </div>
        <div class="form-group col-sm-6 required">
          <label for="type">Parameter / Tag Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="sensors.tag_name">
        </div>
        <div class="form-group col-sm-6 required">
            <label for="type">Show Graph:</label>
            <select class="form-control" required name="type" v-model.number="sensors.show_graph">
              <option value="1" style="color:black;">Yes</option>
              <option value="2" style="color:black;">No</option>
            </select>
        </div>
        <div class="form-group col-sm-12 col-md-12">
          <label for="summary_columns">Summary Column:</label>
          <Multiselect mode="multiple" v-model="summary_columns" :options="summaryOptions" valueProp="valueProp" label="label" :searchable="true" @change="onChangeSummaryColumn($event)">
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                <span v-for="(item2, index2) in values" :key="index2">{{item2.label}},&nbsp;&nbsp;</span>
              </div>
            </template>
          </Multiselect>
        </div>
        <div v-if="on_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6">
          <label for="type">On Value:</label>
          <input class="form-control" name="on_value" type="text" v-model="sensors.on_value">
        </div>
        <div v-if="off_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6">
          <label for="type">Off Value:</label>
          <input class="form-control" name="off_value" type="text" v-model="sensors.off_value">
        </div>
        <div v-if="on_tag_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6">
          <label for="type">On Value:</label>
          <input class="form-control" name="on_tag_value" type="text" v-model="sensors.on_tag_value">
        </div>
        <div v-if="off_tag_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6">
          <label for="type">Off Value:</label>
          <input class="form-control" name="off_tag_value" type="text" v-model="sensors.off_tag_value">
        </div>
        <div v-if="virtual_odometer_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="odometer_value_by">Odometer Value By:</label>
          <select class="form-control" required name="odometer_value_by" v-model.number="sensors.odometer_value_by">
            <option value="" style="color:black;">-- Please Select One --</option>
            <option value="virtual_odometer" style="color:black;">virtual_odometer</option>
          </select>
        </div>
        <div v-if="unit_of_measurement_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="type">Unit of Measurement:</label>
          <input class="form-control" name="unit_of_measurement" required type="text" v-model="sensors.unit_of_measurement">
        </div>
        <div v-if="fuel_tank_name_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="type">Fuel Tank Name:</label>
          <input class="form-control" required name="fuel_tank_name" type="text" v-model="sensors.fuel_tank_name">
        </div>
        <div v-if="full_tank_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="full_tank">Full Tank:</label>
          <input class="form-control" name="full_tank" required type="text" v-model="sensors.full_tank">
        </div>
        <div v-if="full_tank_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="full_tank_value">Full Tank Value:</label>
          <input class="form-control" required name="full_tank_value" type="text" v-model="sensors.full_tank_value">
        </div>
        <div v-if="formula_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="type">Formula:</label>
          <input class="form-control" required name="formula" type="text" v-model="sensors.formula">
          <small>example:([value]*3.3/1000)</small>
        </div>
        <div v-if="shown_value_by_arr.includes(sensors.sensor_type)" class="form-group col-sm-6">
          <label for="type">Shown Value By:</label>
          <select class="form-control" required name="type" v-model.number="sensors.shown_value_by">
            <option value="" style="color:black;">-- Please Select One --</option>
            <option value="tag_value" style="color:black;">tag_value</option>
          </select>
        </div>
        <div v-if="min_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="min_value">Min Value:</label>
          <input class="form-control" name="min_value" required type="text" v-model="sensors.min_value" min="0">
        </div>
        <div v-if="max_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="max_value">Max Value:</label>
          <input class="form-control" required name="max_value" type="text" v-model="sensors.max_value" min="0">
        </div>
        <div v-if="parameter_value_arr.includes(sensors.sensor_type)" class="form-group col-sm-6 required">
          <label for="parameter_value">Parameter Value:</label>
          <input class="form-control" required name="parameter_value" type="text" v-model="sensors.parameter_value">
        </div>
        <div class="form-group col-sm-12">
          <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
          <router-link class="btn btn-default ml-1" to="/sensors">Cancel</router-link>
        </div>
      </div>
    </form>
    <div v-if="calibrations_arr.includes(sensors.sensor_type)" class="col-sm-12">
      <div class="row">
        <div class="form-group col-sm-6">
          <label for="param_val">Parameter Value:</label>
          <input class="form-control" name="param_val" type="number" v-model="param_val">
        </div>
        <div class="form-group col-sm-6">
          <label for="calib_val">Calibrated Value:</label>
          <input class="form-control" name="calib_val" type="number" v-model.number="calib_val">
        </div>
        <div class="form-group col-sm-12">
          <button class="btn btn-info" @click="addParam()">Add</button>
          <button class="btn btn-warning ml-1" @click="resetParam()">Reset</button>
        </div>
        <div class="form-group col-sm-12">
          <label for="type">Data Calibrations:</label>
          <input class="form-control" disabled name="formula" type="text" v-model="data_calibrations_encode">
        </div>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Multiselect from '@vueform/multiselect'
  export default {
    computed: {
        ...mapState('sensors', ['onRequest']),
        data_calibrations_encode(){
          return JSON.stringify(this.data_calibrations)
        }
    },
    data(){
      return {
        sensors: {
          sensor_name: '',
          sensor_type: '',
          on_value: '',
          off_value: '',
          unit_of_measurement: '',
          fuel_tank_name: '',
          shown_value_by: '',
          odometer_value_by: '',
          formula: '',
          show_graph: 1,
          on_tag_value: '',
          off_tag_value: '',
          full_tank: '',
          full_tank_value: '',
          min_value: '',
          max_value: '',
          parameter_value: '',
        },
        summary_columns: [],
        on_value_arr: [
          'acc'
        ],
        off_value_arr: [
          'acc'
        ],
        virtual_odometer_arr: [
          'odometer'
        ],
        unit_of_measurement_arr: [
          'odometer',
          'fuel_tank_calibration',
          'temperature',
          'engine_hours',
          'battery',
          'fuel_tank',
          'gsm',
          'load',
          'numerical',
          'satellites',
          'speed_ecm',
          'tachometer',
          'temperature_calibration',
        ],
        fuel_tank_name_arr: [
          'fuel_tank',
          'fuel_tank_calibration',
        ],
        formula_arr: [
          'fuel_tank',
          'fuel_tank_calibration',
          'temperature',
          'load',
          'numerical',
          'speed_ecm',
          'tachometer',
        ],
        shown_value_by_arr: [
          'battery',
          'temperature',
        ],
        on_tag_value_arr: [
          'door',
          'engine',
          'ignition',
          'logical',
          'route_color',
          'seatbelt',
        ],
        off_tag_value_arr: [
          'door',
          'engine',
          'ignition',
          'logical',
          'route_color',
          'seatbelt',
        ],
        full_tank_arr: [
          'fuel_tank',
        ],
        full_tank_value_arr: [
          'fuel_tank',
        ],
        min_value_arr: [
          'gsm',
        ],
        max_value_arr: [
          'gsm',
        ],
        parameter_value_arr: [
          'harsh_acceleration',
          'harsh_breaking',
        ],
        calibrations_arr: [
          'fuel_tank_calibration',
          'temperature_calibration',
        ],
        param_val: '',
        calib_val: '',
        summaryOptions: [
          {valueProp: 'MIN', label: 'MIN'},
          {valueProp: 'MAX', label: 'MAX'},
          {valueProp: 'SUM', label: 'SUM'},
          {valueProp: 'COUNT', label: 'COUNT'},
          {valueProp: 'AVG', label: 'AVG'},
        ],
        data_calibrations: {},
      }
    },
    components:{
      Multiselect,
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        if(this.calibrations_arr.includes(this.sensors.sensor_type)){
          var size = 0;
          for (let key in this.data_calibrations) {
            if(key)
            size++
          }
          if(size < 2){
            alert('minimum 2 calibration data required')
            return;
          }
          this.sensors.calibrations = this.data_calibrations
        }
        this.sensors.summary_columns = this.summary_columns.toString()
        this.add(this.sensors)
      },
      ...mapActions('sensors', ['add']),
      addParam(){
        this.data_calibrations = Object.assign(this.data_calibrations, {[this.param_val]:this.calib_val})
      },
      resetParam(){
        this.data_calibrations = {}
      },
      onChangeSummaryColumn(event){
        this.summary_columns = event
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Sensor')
    },
  }
</script>
<style>
.item{
  width: 16vw;
  padding: 10px;
  border: 1px solid black;
  margin: 1px 1px;
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
