<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-12 required">
            <label for="account_id">Account:</label>
            <Select2 name="account_id" v-model.number="account_id" :options="accountOptions"/>
          </div>          
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-12">
      <Datatable v-bind:entries="stops" :columns="columns" :title="'Stops'"/>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('stops', ['stops', 'onRequest']),
      ...mapState('accounts', ['accounts']),
      ...mapState('users', ['user']),
      columns(){
        return [
          {name: 'stop_name', text: 'Stop Name'},
          {name: 'stop_code', text: 'Stop Code'},
          {name: 'stop_address', text: 'Address'},
          {name: 'lat', text: 'Latitude', class: 'text-right'},
          {name: 'lng', text: 'Longitude', class: 'text-right'},
          {name: 'radius', text: 'Radius', class: 'text-right'},
          {name: 'description', text: 'Description'},
          {name: 'status', text: 'Status',
            raw: {
              type: 'Text',
              fields: [
                {value: '1', text: 'Active'},
                {value: '2', text: 'Inactive'},
              ]
            }
          },
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                getCapability('edit_stop', this.user) ? {event: 'edit_stop', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                getCapability('delete_stop', this.user) ? {event: 'stops/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    data(){
      return {
        account_id: null,
        accountOptions: [],
      }
    },
    components:{
      Datatable,
      Select2,
    },
    methods: {
      ...mapActions('stops', ['get_stop_by_account']),
      ...mapActions('accounts', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_stop_by_account({account_id: this.account_id})
      },
    },
    created() {
      this.$emit('onChildInit', 'Stops', getCapability('add_stop', this.user) ? '/stops/add' : '')
      this.get_all()
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
            if(this.accountOptions.length > 0){
              this.account_id = this.accountOptions[0].id
              this.handleSubmit()
            }
          }
          return
        }
      },
    }
  }
</script>
