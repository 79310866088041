<template>
  <form @submit.prevent="handleSubmit">
    <div v-if="isDrawPolygon == false" class="row">
      <div class="form-group col-sm-12 required">
        <label for="parent_id">Account:</label>
        <Select2 name="account_id" v-model.number="stop.account_id" :options="accountOptions"/>
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
          <label for="stop_name">Stop Name:</label>
          <input class="form-control" required="" name="stop_name" type="text" v-model="stop.stop_name">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="stop_code">Stop Code:</label>
        <input class="form-control" required="" name="stop_code" type="text" v-model="stop.stop_code">
      </div>
      <div class="form-group col-sm-12">
        <label for="stop_address">Address:</label>
        <textarea class="form-control" name="stop_address" v-model="stop.stop_address"></textarea>
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="lat">Latitude:</label>
        <input class="form-control" required="" name="lat" type="number" v-model.number="stop.lat" step="any">
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="lng">Longitude:</label>
        <input class="form-control" required="" name="lng" type="number" v-model.number="stop.lng" step="any">
      </div>
      <div class="form-group col-sm-12 col-md-4 required">
        <label for="radius">Radius:</label>
        <input class="form-control" required="" name="radius" type="number" v-model.number="stop.radius">
      </div>
      <div class="form-group col-sm-12">
        <label for="polygon">Polygon:</label>
        <textarea class="form-control" readonly name="polygon" v-model="polygons"></textarea>
      </div>
      <div class="form-group col-sm-12">
        <label for="description">Description:</label>
        <textarea class="form-control" name="description" v-model="stop.description"></textarea>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Status:</label>
        <select class="form-control" name="status" v-model.number="stop.status">
          <option value="1" style="color:black;">Active</option>
          <option value="2" style="color:black;">Inactive</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/stops">Cancel</router-link>
        <button class="btn btn-warning ml-1" value="Save" @click.prevent="drawPolygon(true)">Edit Polygon</button>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <GMapMap
          :center="center"
          :zoom="18"
          ref="gmap"
          map-type-id="terrain"
          style="width: 100%; height: 50vh"
          :disableDefaultUI="true"
          :options="{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: true,
          }"
        >
          <GMapCircle
            v-if="stop && stop.lat && stop.lng && !isDrawPolygon && stop.polygon.length < 1"
            :center="{lat: stop.lat, lng: stop.lng}"
            :radius="stop.radius"
            :options="{fillColor:'blue',fillOpacity:0.5}"
          />
          <GMapPolygon
            ref="polygon_map"
            :paths="stop.polygon"
            :options="{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 3,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }"
          />
          <GMapMarker
            v-if="stop && stop.lat && stop.lng"
            :position="{lat: stop.lat, lng: stop.lng}"
            :clickable="false"
            :draggable="false"
          />
        </GMapMap>
      </div>
      <div v-if="isDrawPolygon" class="col-sm-12 mt-2">
        <button class="btn btn-success" @click.prevent="drawPolygon(false)">Done</button>
        <button class="btn btn-warning ml-1" @click.prevent="clearPolygon()">Clear</button>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  import Select2 from 'vue3-select2-component';
  export default {
    computed: {
      ...mapState('accounts', ['accounts']),
      ...mapState('stops', ['onRequest']),
      google: VueGoogleMaps,
      polygons(){
        if(this.stop.polygon){
          return JSON.stringify(this.stop.polygon)
        }
        return []
      }
    },
    components:{
      Select2
    },
    data(){
      return {
        stop: {
          account_id: '',
          stop_name: '',
          stop_code: '',
          stop_address: '',
          lat: '',
          lng: '',
          radius: '',
          description: '',
          status: 1,
          polygon: []
        },
        center: {lat: -6.21462, lng: 106.84513},
        isDrawPolygon: false,
        accountOptions: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {stop} = this
        this.add(stop)
      },
      ...mapActions('stops', ['add']),
      ...mapActions('accounts', ['get_all']),
      drawPolygon(bool){
        this.isDrawPolygon = bool
        const self = this
        this.$refs.gmap.$mapPromise.then(map=> {
          if(self.isDrawPolygon){
            window.google.maps.event.clearListeners(map, 'click')
            map.addListener("click", (mapsMouseEvent) => {
              self.addPolygon({lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng()})
            });
          } else {
            window.google.maps.event.clearListeners(map, 'click')
            map.addListener("click", (mapsMouseEvent) => {
              if(self.stop){
                self.stop.lat = mapsMouseEvent.latLng.lat()
                self.stop.lng = mapsMouseEvent.latLng.lng()
              }
            });
          }
        })
      },
      addPolygon(item){
        this.stop.polygon.push(item)
        this.$refs.polygon_map.$polygonObject.setPath(this.stop.polygon)
      },
      clearPolygon(){
        this.stop.polygon = []
      }
    },
    created() {
      this.$emit('onChildInit', 'Add New Stop')
      this.get_all()
    },
    mounted(){
      this.drawPolygon(false)
    },
    watch: {
      accounts(nextState, prevState){
        if(nextState !== prevState){
          this.accountOptions = []
          if(nextState.length > 0){
            nextState.map(item => {
              this.accountOptions.push({id:item.id, text:item.account_name})
            })
          }
          return
        }
      },
    }
  }
</script>
