<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div v-if="route_target.account" class="form-group col-sm-12">
          <label for="parent_id">Account:</label>
          <input class="form-control" disabled name="account_id" type="text" v-model="route_target.account.account_name">
      </div>
      <div v-if="route_target.route" class="form-group col-sm-12">
          <label for="route_id">Route:</label>
          <input class="form-control" disabled name="route_id" type="text" v-model="route_target.route.route_name">
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="year">Year:</label>
        <input class="form-control" type="number" name="year" v-model.number="route_target.year" required/>
      </div>
      <div class="form-group col-sm-12 required">
        <label for="status">Month:</label>
        <select class="form-control" required name="status" v-model.number="route_target.month">          
          <option value="" style="color:black;">Please Select One</option>
          <option value="1" style="color:black;">January</option>
          <option value="2" style="color:black;">February</option>
          <option value="3" style="color:black;">March</option>
          <option value="4" style="color:black;">April</option>
          <option value="5" style="color:black;">May</option>
          <option value="6" style="color:black;">June</option>
          <option value="7" style="color:black;">July</option>
          <option value="8" style="color:black;">August</option>
          <option value="9" style="color:black;">September</option>
          <option value="10" style="color:black;">October</option>
          <option value="11" style="color:black;">November</option>
          <option value="12" style="color:black;">December</option>
        </select>
      </div>
      <div class="form-group col-sm-12 col-md-12 required">
        <label for="target">Target:</label>
        <input class="form-control" type="number" name="target" v-model.number="route_target.target" required/>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save" :disabled="this.onRequest">
        <router-link class="btn btn-default ml-1" to="/route_targets">Cancel</router-link>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('route_targets', ['route_target', 'onRequest']),
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        this.route_target.month = parseInt(this.route_target.month)
        this.route_target.year = parseInt(this.route_target.year)
        this.edit(this.route_target)
      },
      ...mapActions('route_targets', ['edit', 'get']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Route Target')
      this.get(this.$route.params.id)
    },
  }
</script>
