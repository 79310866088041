<template>
  <div class="row">
    <div class="col-sm-12">
      <Datatable v-bind:entries="data" :columns="columns" :title="'Ceiba Devices'"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import { getCapability } from '../_helpers'
  export default {
    computed: {
      ...mapState('sales', ['saless', 'onRequest']),
      ...mapState('users', ['user']),
      columns(){
        return [
            {name: 'sales_name', text: 'Sales Name', order: 'asc'},
            {name: 'sales_code', text: 'Sales Code'},
            {name: 'accounts', text: 'Accounts'},
            {name: 'total_gps', text: 'Total Device', class: 'text-right'},
            {name: 'status', text: 'Status',
              raw: {
                type: 'Text',
                fields: [
                  {value: '1', text: 'Active'},
                  {value: '2', text: 'Inactive'},
                ]
              }
            },
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  getCapability('edit_sales', this.user) ? {event: 'edit_sales', text: 'Edit', method: 'get', class: 'btn btn-primary'} : {},
                  getCapability('delete_sales', this.user) ? {event: 'sales/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
                ]
              }
            },
          ]
      }
    },
    data(){
      return {
        account_id: null,
        data: [],
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('sales', ['get_all']),
      ...mapActions('alert', ['error', 'clear']),
    },
    created() {
      this.$emit('onChildInit', 'Sales', getCapability('add_sales', this.user) ? '/sales/add' : '')
      this.get_all()
    },
    watch: {
      saless(nextState, prevState){
        if(nextState !== prevState){
          this.data = []
          if(nextState.length > 0){
            nextState.map(item => {
              let accounts = ""
              let total_gps = 0
              item.sales_accounts.map(item => {
                accounts += item.account_name + " ("+item.total_gps+" Devices), "
                total_gps += item.total_gps
              })
              this.data.push({...item, ...{accounts, total_gps}})
            })
          }
          return
        }
      },
    }
  }
</script>
