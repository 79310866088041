<template>
    <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6">
          <label for="parent_id">Account:</label>
          <input class="form-control" v-if="route.account" disabled name="route_name" type="text" v-model="route.account.account_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
          <label for="route_name">Route Name:</label>
          <input class="form-control" disabled name="route_name" type="text" v-model="route.route_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_code">Route Code:</label>
        <input class="form-control" disabled name="route_code" type="text" v-model="route.route_code">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_distance">Distance (km):</label>
        <input class="form-control" disabled name="route_distance" type="number" step="any" min="0" v-model.number="route.route_distance">
      </div>
      <div v-if="route.route_start" class="form-group col-sm-12 col-lg-6">
        <label for="route_start">Start:</label>
        <input class="form-control" disabled name="route_start" type="text" v-model="route.route_start.stop_name">
      </div>
      <div v-if="route.route_end" class="form-group col-sm-12 col-lg-6">
        <label for="route_end">End:</label>
        <input class="form-control" disabled name="route_end" type="text" v-model="route.route_end.stop_name">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_direction">Direction:</label>
        <input class="form-control" disabled v-if="route.route_direction == 1" name="route_end" type="text" value="One Way">
        <input class="form-control" disabled v-if="route.route_direction == 2" name="route_end" type="text" value="Two Ways">
        <input class="form-control" disabled v-if="route.route_direction == 3" name="route_end" type="text" value="Sub Routes">
      </div>
      <div class="form-group col-sm-12 col-lg-6">
        <label for="route_direction">Route Color:</label>
        <input class="form-control" disabled type="color" name="route_color" v-model="route.route_color">
      </div>
      <div class="form-group col-sm-12">
        <label for="upload_file">Upload: </label>
        <br/>
        <input class="" type="file" name="upload_file" id="upload_file" accept=".kml" @change="change($event)">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/routes">Cancel</router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <GMapMap
            :center="center"
            :zoom="14"
            ref="gmap"
            map-type-id="terrain"
            style="width: 100%; height: 50vh"
        >
          <GMapPolyline v-if="route.route_points"
            :path="route.route_points"
            :options="{ strokeColor:route.route_color, strokeWeight: 2}"
            />
          <GMapCircle
            v-for="circle in stopCircle"
            :key="circle"
            :center="{lat: circle.stop.lat, lng: circle.stop.lng}"
            :radius="circle.stop.radius"
            :options="{fillColor:'blue',fillOpacity:0.5}"
          />
          <MapStopComponent
            v-for="circle in stopCircle"
            :key="circle"
            :gmap="this.$refs.gmap"
            :stop="circle.stop"/>
          <GMapPolygon
            v-for="poly in stopPoly"
            :key="poly"
            :paths="poly.stop.polygon"
            :options="{
              strokeColor: '#FF0000',
              strokeOpacity: 0.8,
              strokeWeight: 3,
              fillColor: '#FF0000',
              fillOpacity: 0.35,
            }"
          />
          <MapStopComponent
            v-for="poly in stopPoly"
            :key="poly"
            :gmap="this.$refs.gmap"
            :stop="poly.stop"/>
        </GMapMap>
      </div>
    </div>
    <div v-if="this.onRequest" class="modal-backdrop fade show" style="display:flex; align-items: center; justify-content: center;">
      <img :src="require('../assets/loading.gif')"/>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import VueGoogleMaps from '@fawmi/vue-google-maps'
  import MapStopComponent from '../components/MapStopComponent.vue'
  export default {
    computed: {
        ...mapState('routes', ['onRequest', 'route']),
        google: VueGoogleMaps
    },
    data() {
      return {
        center: {lat: -6.21462, lng: 106.84513},
        stopCircle: [],
        stopPoly: [],
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {id, route_points} = this.route
        this.edit_route_points({id, route_points})
      },
      ...mapActions('routes', ['edit_route_points', 'get']),
      change(event){
        if(event.target.files.length > 0){
          this.parseDocument(event.target.files[0])
        }
      },
      parseDocument(file) {
        let fileReader = new FileReader()
        fileReader.onload = async (e) => {
          let result = await this.extractGoogleCoords(e.target.result)

          //Do something with result object here
          this.route.route_points = result
          this.$refs.gmap.$mapPromise.then(() => {
            let bounds = new window.google.maps.LatLngBounds()
            result.forEach(loc => {
              bounds.extend(loc)
            })
            this.$nextTick().then(() => {
              this.$refs.gmap.fitBounds(bounds)
            })
            this.$refs.gmap.panToBounds(bounds)
          })
        }
        fileReader.readAsText(file)
      },
      async extractGoogleCoords(plainText) {
        let parser = new DOMParser()
        let xmlDoc = parser.parseFromString(plainText, "text/xml")
        let lines = []

        if (xmlDoc.documentElement.nodeName == "kml") {

          for (const item of xmlDoc.getElementsByTagName('Placemark')) {
            // let placeMarkName = item.getElementsByTagName('name')[0].childNodes[0].nodeValue.trim()
            let linestrings = item.getElementsByTagName('LineString')

            /** POLYGONS PARSE **/
            for (const linestring of linestrings) {
              let coords = linestring.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
              let points = coords.split(/\s+/)
              for (const point of points) {
                let coord = point.split(",")
                lines.push({ lat: +coord[1], lng: +coord[0] })
              }
            }
          }
        } else {
          throw "error while parsing"
        }

        return lines

      },
    },
    created() {
      this.$emit('onChildInit', 'Route Draw')
      this.get(this.$route.params.id)
    },
    components:{
      MapStopComponent
    },
    watch: {
      route (nextState, prevState) {
        if(nextState !== prevState){
          if(nextState.route_points && nextState.route_points.length > 0){
            this.$refs.gmap.$mapPromise.then(() => {
              let bounds = new window.google.maps.LatLngBounds()
              nextState.route_points.forEach(loc => {
                bounds.extend(loc)
              })
              this.$nextTick().then(() => {
                this.$refs.gmap.fitBounds(bounds)
              })
              this.$refs.gmap.panToBounds(bounds)
            })
          }
          if(nextState.route_stops && nextState.route_stops.length > 0){
            nextState.route_stops.map(item => {
              if(item.stop.polygon && item.stop.polygon.length > 2){
                this.stopPoly.push(item)
              } else {
                this.stopCircle.push(item)
              }
            })
          }
          return
        }
      }
    },
  }
</script>
